import { useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { Link, useSearchParams } from "react-router-dom";
import { getPostsFeatured } from "../../../services/posts.service";
import PostModel from "../../../interfaces/models/post.model";
import { Loading } from "../../../components/Loading";
import IconShare from "../../../components/share";

export const OfertasDestaque = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryString = searchParams.get("query");

  const [loading, setLoading] = useState<boolean>(false);
  const [posts, setPosts] = useState<PostModel[]>([]);

  const localStorageKey = "featuredPosts";

  const saveToLocalStorage = (data: PostModel[]) => {
    localStorage.setItem(localStorageKey, JSON.stringify(data));
  };

  const loadFromLocalStorage = (): PostModel[] => {
    const data = localStorage.getItem(localStorageKey);
    return data ? JSON.parse(data) : [];
  };

  async function fetchPostsFeatured(): Promise<void> {
    setLoading(true);

    getPostsFeatured(queryString)
      .then((res) => {
        const fetchedPosts = res.data;
        const storedPosts = loadFromLocalStorage();

        const isNewData =
          JSON.stringify(fetchedPosts) !== JSON.stringify(storedPosts);

        if (isNewData) {
          saveToLocalStorage(fetchedPosts);
          setPosts(fetchedPosts);
        } else {
          setPosts(storedPosts);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    const storedPosts = loadFromLocalStorage();
    if (storedPosts.length > 0) {
      setPosts(storedPosts);
    }

    fetchPostsFeatured();
  }, [queryString]);

  return (
    <div className="ofertas-destaque">
      <div className="d-flex align-items-center flex-column-reverse flex-md-row justify-content-md-between mb-5">
        <div className="d-flex align-items-center flex-column justify-content-center flex-md-row justify-content-md-start flex-wrap">
          <h4 className="color-primary text-gilroy-semibold">
            Grupos em destaque
          </h4>
          <p className="color-secondary mx-3 mb-0 text-gilroy-semibold text-subtitle">
            Grupos que estão sendo mais aguardadas
          </p>
        </div>
        <div className="mb-3">
          {queryString && (
            <button
              className="btn btn-danger"
              onClick={() => {
                setSearchParams({
                  query: "",
                });
              }}
            >
              Limpar pesquisa
            </button>
          )}
        </div>
      </div>

      <div className="row mb-4">
        {loading && <Loading center color="#062F53" />}

        {!loading && posts.length === 0 && (
          <p className="color-secondary text-gilroy-semibold h5 text-center text-md-start">
            Nenhum grupo em destaque por enquanto.
          </p>
        )}

        {!loading &&
          posts.map((post) => (
            <div
              key={post.id}
              className="col-6 col-sm-6 col-md-4 col-lg-3 mt-30 position-relative"
              style={{ marginBottom: "80px" }}
            >
              <div className="mb-1">
                <Link to={`/posts/${post.id}`}>
                  <img
                    src={post.poster}
                    className="config-image"
                    alt="Imagem"
                  />
                </Link>
                <div>
                  <img
                    src={`/images/categories/${post.category?.systemKey}_up.svg`}
                    className="img-fluid mt-n1"
                    style={{ marginTop: "-20px" }}
                    alt={post.category?.title}
                  />
                </div>
                <div className="d-flex icons-category justify-content-end">
                  <a
                    href={`https://api.whatsapp.com/send?text=Veja ${
                      post.title
                    } - ${
                      post.preco > 0
                        ? `R$ ${post.preco} ${post.pagamento}`
                        : post.pagamento
                    }! ${
                      window.location.href
                    } Baixe o SuperGrupos para não perder nenhum grupo`}
                    target="_blank"
                    rel="noreferrer"
                    className="icon-whatsapp shadow d-flex justify-content-center align-items-center rounded-circle p-2 ms-2"
                  >
                    <FaWhatsapp className="text-white" />
                  </a>
                  <IconShare post={post} />
                </div>
                <div className="text-card mt-2">
                  <Link
                    to={`/posts/${post.id}`}
                    className="text-black fw-semibold text-md-start text-gilroy-semibold"
                    style={{ marginBottom: "-2px" }}
                  >
                    {post.title}
                  </Link>
                  <div
                    className="d-flex align-items-center"
                    style={{ fontSize: "13px" }}
                  >
                    <IoLocationSharp className="icon-color" />
                    <p className="mb-0 mx-1 text-light-emphasis fw-semibold text-gilroy-semibold">
                      {post.cidade}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="border mb-4"></div>
    </div>
  );
};
