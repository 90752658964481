import { useForm } from "react-hook-form";
import { FaArrowRight } from "react-icons/fa";
import Input from "../../../components/Form/Input";
import RegisterArgs from "../../../interfaces/args/register.args";
import { useState } from "react";
import { register } from "../../../services/auth.service";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { Loading } from "../../../components/Loading";

interface FormValues {
  fullName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}

export const Form = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    values: {
      fullName: "",
      email: "",
      password: "",
      passwordConfirmation: "",
    },
  });

  async function handleRegister(data: FormValues): Promise<void> {
    let args: RegisterArgs = {
      fullName: data.fullName,
      email: data.email,
      password: data.password,
    };

    setLoading(true);

    register(args)
      .then(() => {
        toast.success("Cadastrado com sucesso");
        navigate("/login");
      })
      .catch((err) => {
        toast.error(err.response.data[0]);
      })
      .finally(() => setLoading(false));
  }

  return (
    <form onSubmit={handleSubmit(handleRegister)} className="w-100 mb-4">
      <div className="row g-3">
        <div className="col-md-6">
          <Input
            control={control}
            errors={errors}
            name="fullName"
            validation={{}}
            type="text"
            placeholder="Nome sobrenome"
          />
        </div>

        <div className="col-md-6">
          <Input
            control={control}
            errors={errors}
            name="email"
            validation={{
              required: "Campo e-mail é obrigatório",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Endereço de e-mail é inválido",
              },
            }}
            type="email"
            placeholder="exemplo@email.com"
          />
        </div>

        <div className="col-md-6">
          <Input
            type="password"
            control={control}
            errors={errors}
            name="password"
            validation={{ required: "Campo senha é obrigatório" }}
            placeholder="Informe sua senha aqui"
          />
        </div>

        <div className="col-md-6">
          <Input
            type="password"
            control={control}
            errors={errors}
            name="passwordConfirmation"
            validation={{
              required: "Campo confirmação de senha é obrigatório",
            }}
            placeholder="Digite sua senha novamente"
          />
        </div>
      </div>

      <button
        type={loading ? "button" : "submit"}
        className="btn btn-yellow w-100 mt-3 border-0"
        disabled={loading}
      >
        {loading ? (
          <Loading />
        ) : (
          <span className="align-middle">Criar minha conta</span>
        )}

        <FaArrowRight />
      </button>
    </form>
  );
};
