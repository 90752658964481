import { Link, useNavigate } from "react-router-dom";
import MainLayout from "../../components/Layout/Main";
import { useCheckAuth } from "../../hooks/useCheckAuth";
import { useState } from "react";
import UserModel from "../../interfaces/models/user.model";
import { deleteUser } from "../../services/user.service";
import toast from "react-hot-toast";
import Modal from "../../components/Modal";
import { Loading } from "../../components/Loading";

export default function About() {
  const isAuth: boolean = useCheckAuth();

  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState<boolean>(false);
  const [perfil, setPerfil] = useState<UserModel>({} as UserModel);

  function handleModalDeleteOpen(perfil: UserModel) {
    setPerfil(perfil);
    setIsModalDeleteOpen(true);
  }

  function handleModalDeleteClose() {
    setIsModalDeleteOpen(false);
    setPerfil({} as UserModel);
  }

  function logout(): void {
    localStorage.clear();
    navigate("/");
  }

  async function handleDeletePerfil(): Promise<void> {
    setLoading(true);

    deleteUser()
      .then(() => {
        toast.success("Perfil deletado com sucesso!");
        logout();
      })
      .catch((err) => {
        toast.error(err.response.data[0] ?? "Erro ao deletar perfil");
      })
      .finally(() => setLoading(false));
  }

  return (
    <MainLayout background="transparent">
      <div className="container-xl text-gilroy-semibold">
        <p className="title-about mb-4">Sobre o SuperGrupos</p>
        <p className="description-about">
          Olá, sejam muito bem-vindos ao SuperGrupos.me! 🤑
        </p>
        <p className="description-about">
          Basicamente, somos uma plataforma que te ajuda a encontrar seus temas
          de chat com uma maior facilidade.
        </p>
        <p className="description-about">Viu como é fácil?.</p>
        <p className="description-about">
          Aproveite para nos seguir no Instagram e se tiver qualquer dificuldade
          não deixe de entrar em contato conosco pelo contato@supergrupos.me
        </p>
        <p className="description-about">Espero você! 😻</p>
        <Link to="/privacy" className="description-about text-primary">
          Termos de serviço e política de privacidade.
        </Link>

        {isAuth ? (
          <button
            className="btn btn-danger mt-2 rounded-pill"
            onClick={() => handleModalDeleteOpen(perfil)}
          >
            Excluir conta
          </button>
        ) : null}

        <div className="version mt-4">Versão 1.0.2</div>
      </div>

      <Modal close={handleModalDeleteClose} isOpen={isModalDeleteOpen}>
        <div className="row d-flex justify-content-center align-items-center text-center">
          <p className="h5">Deseja excluir PERMANENTEMENTE a sua conta?</p>
          <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-evenly align-items-center mb-3 mt-3">
            <button
              className="btn btn-danger rounded-pill px-3 py-2 h-100 mb-3 mb-sm-0"
              style={{ width: 175 }}
              onClick={handleModalDeleteClose}
            >
              Cancelar
            </button>

            <button
              className="btn btn-danger rounded-pill px-3 py-2 h-100"
              style={{ width: 175 }}
              onClick={handleDeletePerfil}
            >
              {loading ? (
                <Loading />
              ) : (
                <span className="align-middle">Excluir</span>
              )}
            </button>
          </div>
        </div>
      </Modal>
    </MainLayout>
  );
}
