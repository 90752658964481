import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AdminLayout from "../components/Layout/Admin";
import MainLayout from "../components/Layout/Main";
import NotFound from "../components/not-found";
import PrivateRoute from "../components/private-route";
import {
  About,
  AdminPostEdit,
  AdminPosts,
  Login,
  PostCreate,
  PostDetails,
  Posts,
  PostsByCategory,
  Privacy,
  Profile,
  Register,
  Users,
  Parameters,
  PushNotification,
  AccountManage,
  AllPosts,
  RecoverPassword,
} from "../pages";
import ResetPassword from "../pages/ResetPassword";

export default function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/account/recover" element={<RecoverPassword />} />

        <Route path="/account/recover/:token" element={<ResetPassword />} />


        <Route path="/" element={<Posts />} />
        <Route path="/posts" element={<Posts />} />
        <Route
          path="/posts/category/:categoryId"
          element={<PostsByCategory />}
        />
        <Route
          path="/posts/new"
          element={
            <MainLayout>
              <PrivateRoute>
                <PostCreate />
              </PrivateRoute>
            </MainLayout>
          }
        />
        <Route path="/posts/:postId" element={<PostDetails />} />
        <Route path="/posts/recent" element={<AllPosts />} />

        <Route path="/privacy" element={<Privacy />} />
        <Route path="/about" element={<About />} />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />

        {/* Admin Routes */}
        <Route
          path="/admin"
          element={
            <PrivateRoute>
              <AdminPosts />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/posts"
          element={
            <PrivateRoute>
              <AdminPosts />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/posts/new"
          element={
            <PrivateRoute>
              <AdminLayout>
                <PostCreate />
              </AdminLayout>
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/posts/:postId/edit"
          element={
            <PrivateRoute>
              <AdminPostEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/users"
          element={
            <PrivateRoute>
              <Users />
            </PrivateRoute>
          }
        />
        <Route path="/admin/parameters" element={<Parameters />} />
        <Route
          path="/admin/posts/:postId/push-notification"
          element={<PushNotification />}
        />
        <Route path="/admin/account" element={<AccountManage />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}
