import { IoLocationSharp } from "react-icons/io5";
import { FaArrowRight, FaWhatsapp } from "react-icons/fa";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import PostModel from "../../../interfaces/models/post.model";
import { getPostsNext } from "../../../services/posts.service";
import { Loading } from "../../../components/Loading";
import IconShare from "../../../components/share";

export const OfertasRecentes = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const queryString = searchParams.get("query");

  const [posts, setPosts] = useState<PostModel[]>([]);
  const [filteredPosts, setFilteredPosts] = useState<PostModel[]>([]);
  const searchTerm = localStorage.getItem("searchTerm") || "";

  const localStorageKey = "recentsPosts";

  const saveToLocalStorage = (data: PostModel[]) => {
    localStorage.setItem(localStorageKey, JSON.stringify(data));
  };

  const loadFromLocalStorage = (): PostModel[] => {
    const data = localStorage.getItem(localStorageKey);
    return data ? JSON.parse(data) : [];
  };

  async function fetchPosts(): Promise<void> {
    setLoading(true);

    getPostsNext(queryString)
      .then((res) => {
        const fetchedPosts = res.data;
        const storedPosts = loadFromLocalStorage();

        const isNewData =
          JSON.stringify(fetchedPosts) !== JSON.stringify(storedPosts);

        if (isNewData) {
          saveToLocalStorage(fetchedPosts);
          setPosts(fetchedPosts);
        } else {
          setPosts(storedPosts);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    const storedPosts = loadFromLocalStorage();
    if (storedPosts.length > 0) {
      setPosts(storedPosts);
    }

    fetchPosts();
  }, [queryString]);

  useEffect(() => {
    const storedSearchString = localStorage.getItem("searchString");

    if (storedSearchString) {
      const filtered = posts.filter((post) =>
        post.title?.toLowerCase().includes(storedSearchString.toLowerCase())
      );
      setFilteredPosts(filtered);
    } else {
      setFilteredPosts(posts);
    }
  }, [posts]);

  return (
    <div className="ofertas-recentes">
      <div className="align-items-center flex-column flex-md-row mb-5 text-center text-md-start">
        <h4 className="color-primary fw-semibold text-gilroy-semibold">
          Populares do mês
        </h4>
        <p className="color-secondary fw-semibold small-text mb-0 text-gilroy-semibold text-subtitle">
          Grupos adicionados nos últimos 30 dias
        </p>
      </div>

      <div className="row mb-4">
        {loading && <Loading center color="#062F53" />}

        {!loading && filteredPosts.length === 0 && (
          <p className="color-secondary text-gilroy-semibold h5 text-center text-md-start">
            Nenhum grupo recente por enquanto.
          </p>
        )}

        {!loading &&
          filteredPosts.map((post) => (
            <div
              key={post.id}
              className="col-6 col-md-4 col-lg-4 col-sm-6 mb-5 position-relative"
            >
              <Link to={`/posts/${post.id}`}>
                <img src={post.poster} className="cards-hilight" alt="Imagem" />
              </Link>
              <img
                src={`/images/categories/${post.category?.systemKey}_up.svg`}
                className="img-fluid mt-n1"
                style={{ marginTop: "-20px" }}
                alt={post.category?.title}
              />
              <div className="text-card">
                <Link
                  to={`/posts/${post.id}`}
                  className="text-black mb-1 fw-semibold text-center text-md-start text-gilroy-semibold"
                >
                  {post.title}
                </Link>
                <div
                  className="d-flex align-items-center mb-2"
                  style={{ fontSize: "13px" }}
                >
                  <IoLocationSharp className="icon-color" />
                  <p className="mb-0 mx-1 text-dark-emphasis fw-semibold text-gilroy-semibold text-gilroy-semibold">
                    {post.cidade}
                  </p>
                </div>
                <div className="border mb-2"></div>
                <div className="d-flex align-items-center justify-content-center">
                  <a
                    href={`https://api.whatsapp.com/send?text=Veja o grupo ${
                      post.title
                    } - ${
                      post.preco > 0
                        ? `R$ ${post.preco} ${post.pagamento}`
                        : post.pagamento
                    }! ${
                      window.location.href
                    } Baixe o SuperGrupos para não perder nenhum grupo`}
                    target="_blank"
                    rel="noreferrer"
                    className="icon-whatsapp shadow d-flex justify-content-center align-items-center rounded-circle p-2 ms-2"
                  >
                    <FaWhatsapp className="text-white" />
                  </a>
                  <IconShare post={post} />
                </div>
              </div>
            </div>
          ))}

        {/* Button */}
        <div className="col-12 mt-2">
          <Link
            to="/posts/recent"
            className="btn btn-seeall w-100 rounded text-white text-center fw-semibold justify-content-center d-flex align-items-center position-relative text-gilroy-semibold"
          >
            Ver todas
            <span>
              <FaArrowRight className="arrow-icon" />
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};
